import { PORTRAIT } from "../../static/portrait";
import "./component.css";

//importing libraries
import Image from "react-bootstrap/Image";
import Stack from "react-bootstrap/Stack";

const PortraitCards = () => {
  return (
    <div className="portrait-cards-container">
      <Stack direction="vertical" gap={3}>
        <Stack
          direction="horizontal"
          gap={3}
          className="d-flex justify-content-center"
        >
          <Image
            src={PORTRAIT[2].src}
            alt={PORTRAIT[2].alt}
            className="portrait-card"
          />
          <Image
            src={PORTRAIT[3].src}
            alt={PORTRAIT[3].alt}
            className="portrait-card"
          />
          <Image
            src={PORTRAIT[11].src}
            alt={PORTRAIT[11].alt}
            className="portrait-card"
          />
        </Stack>
        <Stack
          direction="horizontal"
          gap={3}
          className="d-flex justify-content-center"
        >
          <Image
            src={PORTRAIT[6].src}
            alt={PORTRAIT[6].alt}
            className="portrait-card"
          />
          <Image
            src={PORTRAIT[10].src}
            alt={PORTRAIT[10].alt}
            className="portrait-card"
          />

          <Image
            src={PORTRAIT[8].src}
            alt={PORTRAIT[8].alt}
            className="portrait-card"
          />
        </Stack>
      </Stack>
    </div>
  );
};

export default PortraitCards;
