import DSC_2718 from "./images/portraits/DSC_2718.jpg";
import DSC_2892 from "./images/portraits/DSC_2892.jpg";
import DSC_2897 from "./images/portraits/DSC_2897.jpg";
import DSC_3258 from "./images/portraits/DSC_3258.jpg";
import DSC_3357 from "./images/portraits/DSC_3357.jpg";
import DSC_3441 from "./images/portraits/DSC_3441.jpg";
import DSC_3486 from "./images/portraits/DSC_3486.jpg";
import DSC_4193 from "./images/portraits/DSC_4193.jpg";
import DSC_4232 from "./images/portraits/DSC_4232.jpg";
import NEF_5062 from "./images/portraits/NEF_5062.jpg";
import NEF_6683 from "./images/portraits/NEF_6683.jpg";
import NEF_6842 from "./images/portraits/NEF_6842.jpg";

export const PORTRAIT = [
  { id: 1, src: DSC_2718, alt: "DSC_2718" },
  { id: 2, src: DSC_2892, alt: "DSC_2892" },
  { id: 3, src: DSC_2897, alt: "DSC_2897" },
  { id: 4, src: DSC_3258, alt: "DSC_3258" },
  { id: 5, src: DSC_3357, alt: "DSC_3357" },
  { id: 6, src: DSC_3441, alt: "DSC_3441" },
  { id: 7, src: DSC_3486, alt: "DSC_3486" },
  { id: 8, src: DSC_4193, alt: "DSC_4193" },
  { id: 9, src: DSC_4232, alt: "DSC_4232" },
  { id: 10, src: NEF_5062, alt: "NEF_5062" },
  { id: 11, src: NEF_6683, alt: "NEF_6683" },
  { id: 12, src: NEF_6842, alt: "NEF_6842" },
];
