import { PORTRAIT } from "../../static/portrait";
import "./component.css";

//importing libraries
import Carousel from "react-bootstrap/Carousel";

const PortraitComponent = () => {
  return (
    <Carousel className="carousel-image-container" interval={1000}>
      <Carousel.Item>
        <img
          src={PORTRAIT[0].src}
          alt="First slide"
          className="carousel-image"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={PORTRAIT[7].src}
          alt="Second slide"
          className="carousel-image"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={PORTRAIT[5].src}
          alt="Third slide"
          className="carousel-image"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={PORTRAIT[9].src}
          alt="Fourth slide"
          className="carousel-image"
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default PortraitComponent;
