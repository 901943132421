import { useEffect } from "react";
import {
  FaGithub,
  FaPython,
  FaJs,
  FaHtml5,
  FaCss3,
  FaReact,
} from "react-icons/fa";
import Table from "react-bootstrap/Table";

const Technology = () => {
  useEffect(() => {
    document.title = "The Golden Pothos";
  }, []);

  return (
    <div className="technology">
      <h1>Tech-stack</h1>
      <div className="technology-description">
        <ul className="tech-list">
          <li>
            <div className="tech-stack">C</div>
          </li>
          <li>
            <div className="tech-stack">
              <FaPython />
            </div>
            <div className="hidden-tech-stack">
              Use Cases: Data Science and Artitificial Intelligence, Web
              Scrapping, and Process Automation <br />
              Libraries Used: Pandas, Numpy, Matplotlib, Scikit-learn,
              BeautifulSoup, Selenium, and Requests.
            </div>
          </li>
          <li>
            <div className="tech-stack">
              <FaHtml5 />
            </div>
          </li>
          <li>
            <div className="tech-stack">
              <FaCss3 />
            </div>
          </li>
          <li>
            <div className="tech-stack">
              <FaJs />
            </div>
          </li>
          <li>
            <div className="tech-stack">
              <FaReact />
            </div>
            <div className="hidden-tech-stack">
              Use Cases: ReactJS and React Native <br />
              Libraries Used: Axios, bootstrap
            </div>
          </li>
        </ul>
      </div>
      <div className="technology-projects">
        <h1>Projects</h1>
        <div className="project-table">
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Year</th>
                <th>Title</th>
                <th>Built With</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2023</td>
                <td>thegoldenpothos.xyz</td>
                <td>
                  <ul>
                    <li>React</li>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>Firebase</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>2023</td>
                <td>
                  Final Year Project: Mobile Application for Travel Sharing
                </td>
                <td>
                  <ul>
                    <li>React Native</li>
                    <li>HTML</li>
                    <li>Gluestack</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>2023</td>
                <td>Cloudhacks 2023</td>
                <td>
                  <ul>
                    <li>React</li>
                    <li>HTML</li>
                    <li>CSS</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>2022</td>
                <td>GovTech STACK The Codes</td>
                <td>
                  <ul>
                    <li>React</li>
                    <li>HTML</li>
                    <li>CSS</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>2021</td>
                <td>kaafei.org</td>
                <td>
                  <ul>
                    <li>Wordpress</li>
                    <li>HTML</li>
                    <li>CSS</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <div className="social-media">
        {" "}
        <a href="https://github.com/keefhub" target="blank">
          <FaGithub className="icon" />
        </a>
      </div>
    </div>
  );
};

export default Technology;
