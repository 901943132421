import { Component } from "react";

class NotFound extends Component {
  render() {
    return (
      <div>
        <p>this page is for error404</p>
      </div>
    );
  }
}

export default NotFound;
