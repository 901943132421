import React from "react";
import { FaInstagram } from "react-icons/fa";

import "../App.css";

import PortraitComponent from "./pageComponents/portraitComponent";
import PortraitCards from "./pageComponents/portraitCards";
import StreetComponent from "./pageComponents/streetComponent";

const Creative = () => {
  return (
    <div className="creative">
      <h1>Profile</h1>
      <div className="creative-description">
        <p>
          I'm a self-taught photographer with a passion that ignited in 2013.
          Over the years, I've delved into various genres of photography,
          embracing the art of capturing <strong>portraits</strong>, exploring
          the grandeur of <strong>landscapes</strong>, and immersing myself in
          the candid moments of the <strong>streets</strong>. <br />
          During my academic journey, I've had the opportunity to combine my
          passion for photography with my studies. As a member of the
          Info-Communication team, I've been entrusted with capturing the
          essence of school events, preserving memorable moments for our
          community. More recently, as a university student, I've taken an
          active role in contributing to my university's community through
          volunteering. This experience has allowed me to merge my photography
          skills with the spirit of volunteerism, creating impactful visual
          content for its community.
        </p>
      </div>

      <div className="photography-container">
        <div className="title">
          <h2>Portraits</h2>
        </div>
        <div className="photography">
          <PortraitComponent />
          <PortraitCards />
        </div>
        <div className="title">
          <h2>Streets</h2>
        </div>
        <div className="photography">
          <StreetComponent />
        </div>
      </div>
      <div className="social-media">
        <a href="https://instagram.com/yypixs" target="blank">
          <FaInstagram className="icon" />
        </a>
      </div>
    </div>
  );
};

export default Creative;
