import DSC_0467 from "./images/street/DSC_0467.jpg";
import DSC_0509 from "./images/street/DSC_0509.jpg";
import DSC_0615 from "./images/street/DSC_0615.jpg";
import DSC_0742 from "./images/street/DSC_0742.jpg";
import DSC_2005 from "./images/street/DSC_2005.jpg";

export const STREET = [
  { id: 1, src: DSC_0467, alt: "DSC_0467" },
  { id: 2, src: DSC_0509, alt: "DSC_0509" },
  { id: 3, src: DSC_0615, alt: "DSC_0615" },
  { id: 4, src: DSC_0742, alt: "DSC_0742" },
  { id: 5, src: DSC_2005, alt: "DSC_2005" },
];
