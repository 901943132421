import { useEffect } from "react";

const Homepage = () => {
  useEffect(() => {
    document.title = "The Golden Pothos";
  }, []);

  return (
    <div className="homepage">
      <div className="description">
        <h1>Hey there! Keith here</h1>
        <p>
          I graduated with a degree in Electrical and Electronics Engineering
          from Nanyang Technological University. <br />
          Now, I'm working as a software engineer. <br />I have a passion for{" "}
          <strong>technology</strong>, <strong>photography</strong>, and{" "}
          <strong>brewing the perfect cup of coffee</strong>.
        </p>
      </div>
    </div>
  );
};

export default Homepage;
