import "./component.css";
import { STREET } from "../../static/street";

//importing libraries
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";

const StreetComponent = () => {
  return (
    <Carousel interval={1000}>
      <Carousel.Item>
        <Image
          src={STREET[0].src}
          alt={STREET[0].alt}
          className="carousel-image"
        />
      </Carousel.Item>
      <Carousel.Item>
        <Image
          src={STREET[1].src}
          alt={STREET[1].alt}
          className="carousel-image"
        />
      </Carousel.Item>
      <Carousel.Item>
        <Image
          src={STREET[2].src}
          alt={STREET[2].alt}
          className="carousel-image"
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default StreetComponent;
