import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <p> &copy;2023 Keith Lim</p>
      </div>
    </div>
  );
};
export default Footer;
