import "./App.css";

//react libraries
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Component } from "react";

//importing of pages
import Technology from "./pages/technology";
import Creative from "./pages/creative";
import Checkout from "./pages/checkout";
import Contactus from "./pages/contactus";
import Homepage from "./pages/homepage";
import NotFound from "./pages/notfound";
import Footer from "./web-components/footer";

//importing of web components
import Navbar from "./web-components/navbar";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <div id="page-body">
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/technology" element={<Technology />} />
              <Route path="/creative" element={<Creative />} />
              <Route path="/connect" element={<Contactus />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
  r;
}

export default App;
