import { useEffect } from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";

const Contactus = () => {
  useEffect(() => {
    document.title = "The Golden Pothos";
  }, []);
  return (
    <div className="contact-us">
      <div className="contact-us-content">
        <h1> Let's Talk!</h1>
        <br />
        <p>
          I am open to talk about anything under the sun! Whether is it on
          coffee profiles and roasting techniques, photography ideas which you
          like to share, or interesting programming projects!
        </p>
      </div>
      <div className="connect-to-email">
        <div className="title">
          <h1>I am Happy to Connect</h1>
        </div>
        <div className="icon">
          <FaLinkedinIn
            className="icon-linkedin"
            onClick={() => {
              window.open("https://www.linkedin.com/in/lim-keith-540351120/");
            }}
          />
          <FaEnvelope
            className="icon-email"
            onClick={() => {
              window.open(
                "mailto:limy0313@e.ntu.edu.sg?subject=Subject&body=Body%20goes%20here"
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Contactus;
