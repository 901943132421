import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const NavBar = () => {
  return (
    <Navbar data-bs-theme="dark" className="d-flex justify-content-center">
      <Nav variant="underline">
        <Nav.Link href="/">Home</Nav.Link>
        <Nav.Link href="/creative">Photography</Nav.Link>
        <Nav.Link href="/technology">Technology</Nav.Link>
        <Nav.Link href="https://kaafei.org" target="_blank">
          The Pefect Cup of Coffee
        </Nav.Link>
        <Nav.Link href="/connect">Let's Connect!</Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default NavBar;
