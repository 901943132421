import { Component } from "react";

class Checkout extends Component {
  render() {
    return (
      <div>
        <p>this is a checkout page</p>
      </div>
    );
  }
}

export default Checkout;
